class Menu {
  constructor() {
    let $menu = $('.js-navbox');
    $menu.on('click', () => {
      // $menu.toggleClass('is-active');

      if ($menu.hasClass('is-active')) {
        $menu.removeClass('is-active')
        $('.js-menu-target').fadeOut(500);
        $('html').removeClass('is-modal');

      } else {
        $menu.addClass('is-active')
        $('.js-menu-target').fadeIn(500);
        $('html').addClass('is-modal');
      }
    });
  }
}

export default Menu;