class Acd {
  constructor() {
    $('.js-list__item').on('click', (e) => {
      e.stopPropagation();
      let target = $(e.currentTarget);
      let slide = target.find('>.js-side-inner-list');
      slide.stop(true, true).slideToggle(100, () => {
        if (slide.is(':hidden')) {
          target.removeClass('is-active').removeClass('is-current')
          slide.find('.js-side-inner-list').slideUp();
        } else {
          $('.js-list__item').removeClass('is-active').removeClass('is-current');
          target.addClass('is-active').parents('.js-list__item').addClass('is-active');
        }
      });
    })
    $('.js-list__item.is-current').trigger('click');
  }
}

export default Acd;